import React, {FunctionComponent} from 'react';
import {BrowserRouter, Redirect, Route} from 'react-router-dom';
import {DocsPage} from './components/DocsPage';

export const App: FunctionComponent = () => (
    <BrowserRouter>
      <Route path="/" exact={true} render={() => <Redirect to="/doc" />} />
      <Route path="/doc" component={DocsPage} />
    </BrowserRouter>
);
