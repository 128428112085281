import React, {FunctionComponent} from 'react';
import {RedocStandalone, styled} from 'redoc';
import {DocInfo} from '../services/documentation-service';
import {Header} from './Header';

type DocPageProps = {
  doc: DocInfo | null,
  docs: DocInfo[],
}

const documentationOptions = {
  untrustedSpec: true,
  noAutoAuth: true,
  scrollYOffset: 64,
  theme: {
    headerSize: 64,
    spacing: {
      unit: 5,
      sectionHorizontal: 40,
      sectionVertical: 10,
    },
    extensionsHook(styledName: string, props: any) {
      switch (styledName) {
        case 'H1':
          return `
            margin: 10px 0 0 0;
          `;

        case 'H2':
          return `
            margin: 8px 0;
          `;

        default:
          return;
      }
    }
  }
};

const PageContent = styled.div`
  padding-top: 64px;
`;

export const DocPage: FunctionComponent<DocPageProps> = ({ doc, docs, ...props }) => (
    <>
      <Header doc={doc} docs={docs}/>
      <PageContent>
        {doc && <RedocStandalone specUrl={doc.path} options={documentationOptions}/>}
      </PageContent>
    </>
);
