import AppBar from '@material-ui/core/AppBar';
import {makeStyles} from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import React, {FunctionComponent} from 'react';
import {DocInfo} from '../services/documentation-service';
import {SelectDoc} from './SelectDoc';

type HeaderProps = {
  docs: DocInfo[],
  doc: DocInfo | null
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  title: {
    marginRight: theme.spacing(2),
  },
  toolbar: {
    boxShadow: 'none'
  }
}));

export const Header: FunctionComponent<HeaderProps> = ({docs, doc}) => {
  const classes = useStyles();

  return (
      <div className={classes.root}>
        <AppBar className={classes.toolbar} position="fixed">
          <Toolbar>
            <Typography variant="h6" className={classes.title}>
              {process.env.REACT_APP_TITLE as string}
            </Typography>
            <SelectDoc docs={docs} doc={doc}/>
          </Toolbar>
        </AppBar>
      </div>
  );
};
