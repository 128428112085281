import React, {Component} from 'react';
import {Redirect, Route, RouteComponentProps} from 'react-router';
import {DocInfo, getDocs} from '../services/documentation-service';
import {DocPage} from './DocPage';

type DocState = {
  docs: DocInfo[]
}

export class DocsPage extends Component<RouteComponentProps, DocState> {

  componentWillMount() {
    this.setState({
      docs: []
    });
  }

  componentDidMount() {
    getDocs()
        .then(docs => this.setState({ docs }))
        .catch(() => {
          throw new Error('REACT_APP_API_JSON is invalid.');
        });
  }

  render() {
    const { match } = this.props;
    const { docs } = this.state;

    return (
        <>
          <Route
              path={match.path}
              exact={true}
              render={() => {
                const firstDoc = docs && docs.length ? docs[0] : null;

                return firstDoc
                    ? <Redirect to={`${match.url}/${firstDoc.id}`}/>
                    : <></>
              }}
          />
          <Route
              path={`${match.path}/:id`}
              render={({ match: { params } }: RouteComponentProps<{ id: string }>) => {
                const doc = docs.find(({ id }) => id === params.id) || null;

                return <DocPage doc={doc} docs={docs} />
              }}
          />
        </>
    );
  }
}
