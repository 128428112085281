import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {createStyles, WithStyles, withStyles} from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/ArrowDropDown';
import React, {Component} from 'react';
import {RouteComponentProps, StaticContext, withRouter} from 'react-router';
import {compose} from 'recompose';
import {DocInfo} from '../services/documentation-service';

const styles = createStyles({
  root: {
    width: '100%',
    maxWidth: 360,
  },
  selectText: {
    flex: '0 1 auto',
  },
  icon: {
    fontSize: 32,
  },
});

type SelectDocProps = {
  docs: DocInfo[],
  doc: DocInfo | null,
}

type SelectDocPropsBase =
    WithStyles<typeof styles>
    & RouteComponentProps<any, StaticContext, any>
    & SelectDocProps;

type SelectDocState = {
  anchorEl: null | HTMLElement
}

class SelectDocBase extends Component<SelectDocPropsBase, SelectDocState> {

  componentWillMount() {
    this.setState({ anchorEl: null });
  }

  handleClickListItem(event: React.MouseEvent<HTMLElement>) {
    this.setState({anchorEl: event.currentTarget});
  }

  handleMenuItemClick(event: React.MouseEvent<HTMLElement>, doc: DocInfo) {
    this.props.history.push(`/doc/${doc.id}`);

    this.setState({anchorEl: null});
  }

  handleClose() {
    this.setState({anchorEl: null});
  }

  render() {
    const { anchorEl } = this.state;
    const { docs, doc, classes } = this.props;

    return (
        <div className={classes.root}>
          <List component="nav" aria-label="Selected documentation">
            <ListItem
                button
                aria-haspopup="true"
                aria-controls="lock-menu"
                aria-label="Selected documentation"
                onClick={this.handleClickListItem.bind(this)}
            >
              <ListItemText primary={doc ? doc.title : 'Select documentation'} className={classes.selectText} />
              <DeleteIcon className={classes.icon}/>
            </ListItem>
          </List>
          <Menu
              id="lock-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={this.handleClose.bind(this)}
          >
            {docs.map((option, index) => (
                <MenuItem
                    key={index}
                    selected={option === doc}
                    onClick={event => this.handleMenuItemClick(event, option)}
                >
                  {option.title}
                </MenuItem>
            ))}
          </Menu>
        </div>
    );
  }
}

const enhance = compose<SelectDocPropsBase, SelectDocProps>(
    withStyles(styles),
    withRouter
);

export const SelectDoc = enhance(SelectDocBase);
